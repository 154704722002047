<template>
    <v-card>
        <v-card-title class="text-subtitle-1 font-weight-thin">{{
            dateGroup
        }}</v-card-title>
        <v-card-text>
            <template v-for="(item, i) in items">
                <div :key="i">
                    <span>{{ item.firstName }} {{ item.lastName }}</span
                    >&nbsp;
                    <span>{{
                        $t('dashboard.activityStream.types.' + item.action)
                    }}</span
                    >&nbsp;
                    <span
                        v-if="item.processingActivity"
                        class="font-weight-bold undecorated-link"
                        ><router-link
                            @click.native="$event.stopImmediatePropagation()"
                            :to="{
                                name: 'VvtEditProcessingActivity',
                                params: { id: item.processingActivityId },
                                query: { step: 1 },
                            }"
                            >{{ item.processingActivity }}</router-link
                        ></span
                    >
                    <span v-else class="font-weight-bold">{{ item.prev }}</span><br />
                    <v-tooltip bottom color="indigo" :z-index="100">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                                <div
                                    class="text-caption pt-0 pb-3"
                                    :class="{ 'grey--text': $vuetify.dark }"
                                >
                                    {{ getDateLeft(item.date) }}
                                </div>
                            </div>
                        </template>
                        <span
                            v-text="
                                getToolTipDate(item.date) +
                                    ' ' +
                                    $t('at') +
                                    ' ' +
                                    getToolTipTime(item.date)
                            "
                        ></span>
                    </v-tooltip>
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import {
    parseISO,
    dateTypes,
    formatDate,
    formatDistanceFrom,
} from '@/utils/dateFns';

export default {
    name: 'DashboardActivityDateGroupSimple',
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    props: {
        dateGroup: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: null,
        },
    },
    methods: {
        getDateLeft(date) {
            return formatDistanceFrom(parseISO(date));
        },
        getToolTipDate(date) {
            return formatDate(parseISO(date), dateTypes.fullDate);
        },
        getToolTipTime(date) {
            return formatDate(parseISO(date), dateTypes.time);
        },
    },
};
</script>
