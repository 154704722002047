var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-1 font-weight-thin"},[_vm._v(_vm._s(_vm.dateGroup))]),_c('v-card-text',[_vm._l((_vm.items),function(item,i){return [_c('div',{key:i},[_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),_vm._v("  "),_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.activityStream.types.' + item.action)))]),_vm._v("  "),(item.processingActivity)?_c('span',{staticClass:"font-weight-bold undecorated-link"},[_c('router-link',{attrs:{"to":{
                            name: 'VvtEditProcessingActivity',
                            params: { id: item.processingActivityId },
                            query: { step: 1 },
                        }},nativeOn:{"click":function($event){return $event.stopImmediatePropagation()}}},[_vm._v(_vm._s(item.processingActivity))])],1):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.prev))]),_c('br'),_c('v-tooltip',{attrs:{"bottom":"","color":"indigo","z-index":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('div',{staticClass:"text-caption pt-0 pb-3",class:{ 'grey--text': _vm.$vuetify.dark }},[_vm._v(" "+_vm._s(_vm.getDateLeft(item.date))+" ")])])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(
                            _vm.getToolTipDate(item.date) +
                                ' ' +
                                _vm.$t('at') +
                                ' ' +
                                _vm.getToolTipTime(item.date)
                        )}})])],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }